import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { Alert } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';

import { ImLocation2, ImPhone } from 'react-icons/im';
import { FaEnvelope } from 'react-icons/fa';
import NavbarContact from '../components/NavbarContact';
import Sidebar from '../components/Sidebar';

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const fNameRef = useRef();
  const lNameRef = useRef();
  const emailRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();

  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(false);
  const [notAllowed, setNotAllowed] = useState(true);
  const [notAllowedTxt, setNotAllowedTxt] = useState('');

  const checkInfo = () => {
    if (
      fNameRef.current.value &&
      lNameRef.current.value &&
      messageRef.current.value &&
      emailRef.current.value &&
      subjectRef.current.value
    ) {
      setNotAllowed(false);
      setNotAllowedTxt();
    } else {
      setNotAllowedTxt('Please fill all contact information');
      setNotAllowed(true);
    }
  };

  const sendHandler = () => {
    const serviceID = 'service_rgiwxa5';
    const templateID = 'template_3orhw4j';
    const userID = 'user_Mr0n6sfYEE7PQnaUxnry8';
    const templateParams = {
      Subject: subjectRef.current.value,
      Email: emailRef.current.value,
      message: messageRef.current.value,
      Fname: fNameRef.current.value,
      Lname: lNameRef.current.value
    };
    setLoading(true);

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then(res => {
        fNameRef.current.value = '';
        lNameRef.current.value = '';
        emailRef.current.value = '';
        subjectRef.current.value = '';
        messageRef.current.value = '';
        setLoading(false);
        setMessageSent(true);
      })
      .catch(err => {
        setError(true);
      });
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessageSent(false);
    }, 5000);
  }, [messageSent]);

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 8000);
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setNotAllowedTxt();
    }, 8000);
  }, [notAllowedTxt]);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavbarContact toggle={toggle} />
      <Container id='contact'>
        <Content>
          <TitleContainer>
            <Title>Contact Form</Title>
            {messageSent && <Alert variant='success'>Message Sent</Alert>}
            {error && <Alert variant='danger'>Something Wrong</Alert>}
            {notAllowedTxt && <Alert variant='warning'>{notAllowedTxt}</Alert>}
          </TitleContainer>
          <TextContainer>
            <Text>First Name</Text>
          </TextContainer>
          <Input ref={fNameRef} onChange={checkInfo} />
          <TextContainer>
            <Text>Last Name</Text>
          </TextContainer>
          <Input ref={lNameRef} onChange={checkInfo} />
          <TextContainer>
            <Text>Email</Text>
          </TextContainer>
          <Input ref={emailRef} onChange={checkInfo} />
          <TextContainer>
            <Text>Subject</Text>
          </TextContainer>
          <Input ref={subjectRef} onChange={checkInfo} />
          <TextContainer>
            <Text>Message</Text>
          </TextContainer>
          <TextArea ref={messageRef} onChange={checkInfo} />

          <SubmitBtn disabled={notAllowed} onClick={sendHandler}>
            {loading ? <ClipLoader size={25} /> : 'Send Message'}
          </SubmitBtn>
        </Content>
        <LocationsContainer>
          <LocationColumn>
            <IconContainer>
              <ImLocation2 style={{ fontSize: 25 }} />
            </IconContainer>
            <LocationTxtCont>
              <LocationText>
                Riyadh: 6th & 7th Floor Saudi Fransi Bank Building, King
                Abdulaziz Road, P.O. Box 16300 Riyadh 11454
              </LocationText>
            </LocationTxtCont>
            {/* <LocationTxtCont>
              <LocationText>
                Jeddah: Arabian Bugshan Group, Kilo 3, Old Makkah Road, P.O.Box
                3329, Jeddah 21471
              </LocationText>
            </LocationTxtCont>
            <LocationTxtCont>
              <LocationText>
                Alkhobar: Arabian Bugshan Group, Bugshan Center, Kleenex Area,
                Behind DHL Building, AlKhobar- Dammam Highway, P.O.Box 3790,
                AlKhobar 31952
              </LocationText>
            </LocationTxtCont> */}
          </LocationColumn>
          <LocationColumn>
            <IconContainer>
              
            </IconContainer>
            <LocationTxtCont>
              <LocationText>
                
              </LocationText>
            </LocationTxtCont>
            {/* <LocationTxtCont>
              <LocationText>
                Jeddah: Tel: +966 12 6870141 Fax: +966 12 6804282
              </LocationText>
            </LocationTxtCont>
            <LocationTxtCont>
              <LocationText>
                Alkhobar: Tel: +966 13 8958000 Fax: +966 13 8958258
              </LocationText>
            </LocationTxtCont> */}
          </LocationColumn>
          <LocationColumn>
            <IconContainer>
              <FaEnvelope style={{ fontSize: 25 }} />
            </IconContainer>
            <LocationTxtCont>
              <LocationText>info@hoicom.net</LocationText>
            </LocationTxtCont>
          </LocationColumn>
        </LocationsContainer>
      </Container>
    </>
  );
};

export default Contact;

const Container = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2``;

const Input = styled.input`
  width: 70%;
  height: 40px;
  border: 3px solid #eee;
  border-radius: 15px;

  &:focus {
    border: 3px solid #007aff;
    border-radius: 15px;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  width: 70%;
  height: 100px;
  border: 3px solid #eee;
  border-radius: 15px;

  &:focus {
    border: 3px solid #007aff;
    border-radius: 15px;
    outline: none;
  }
`;

const TextContainer = styled.div`
  width: 70%;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
`;

const Text = styled.p`
  text-align: left;
`;

const SubmitBtn = styled.button`
  width: 20%;
  height: 50px;
  margin-top: 20px;
  background: #eee;
  border-radius: 10px;
  border: none;

  &:hover {
    cursor: pointer;
    border: 2px solid #000;
  }
`;

const LocationsContainer = styled.div`
  width: 100%;
  padding: 20px 5%;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LocationColumn = styled.div`
  width: 33%;
  padding: 10px 5px;
  margin: 0 5px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin: 5px 0;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: center;
`;

const LocationTxtCont = styled.div`
  padding: 0 10px;
  margin: 7px 0;
  width: 80%;
  display: flex;
  justify-content: center;
`;

const LocationText = styled.p`
  text-align: justify;
`;
